import axios from 'axios'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

function doGet(url) {
  return new Promise(function (resolve, reject) {
    apiClient
      .get(url)
      .then(function (yay) {
        resolve(yay.data)
      })
      .catch(function (nay) {
        reject(nay.data)
      })
  })
}

function doPost(url, payload) {
  return new Promise(function (resolve, reject) {
    apiClient
      .post(url, payload)
      .then(function (yay) {
        resolve(yay.data)
      })
      .catch(function (nay) {
        reject(nay.response.data)
      })
  })
}

export default {
  getSchedule() {
    return doGet('/v1/schedule')
  },
  getDirectors() {
    return doGet('/v1/directors')
  },
  getCoaches() {
    return doGet('/v1/coaches')
  },
  getBoD() {
    return doGet('/v1/board')
  },
  doRegistration(registrant) {
    return doPost('/v1/registration', registrant)
  },
}
